import { Moeda } from 'px-business-components';

export default {
  computed: {
    configuracaoMoeda() {
      if (!this.moeda) {
        return Moeda.keys.BRL.currencyConfig;
      }
      return Moeda.keys[this.moeda].currencyConfig;
    },
    configuracaoFormatacaoMoeda() {
      if (!this.moeda) {
        return Moeda.keys.BRL.currencyFormattingOptions;
      }
      return Moeda.keys[this.moeda].currencyFormattingOptions;
    },
    simboloMoeda() {
      if (!this.moeda) {
        return Moeda.keys.BRL.symbol;
      }
      return Moeda.keys[this.moeda].symbol;
    },
    isMoedaEstrangeira() {
      return this.moeda && this.moeda !== Moeda.keys.BRL.key;
    },
    configuracaoMoedaFn() {
      return (moeda) => {
        if (!moeda) {
          return Moeda.keys.BRL.currencyConfig;
        }
        return Moeda.keys[moeda].currencyConfig;
      };
    },
    configuracaoFormatacaoMoedaFn() {
      return (moeda) => {
        if (!moeda) {
          return Moeda.keys.BRL.currencyFormattingOptions;
        }
        return Moeda.keys[moeda].currencyFormattingOptions;
      };
    },
    simboloMoedaFn() {
      return (moeda) => {
        if (!moeda) {
          return Moeda.keys.BRL.symbol;
        }
        return Moeda.keys[moeda].symbol;
      };
    },
    isMoedaEstrangeiraFn() {
      return (moeda) => moeda && moeda !== Moeda.keys.BRL.key;
    },
  },
};
