<template>
  <v-card class="card-shadow mt-3">
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="11">
          <p class="mb-1">
            <span class="title font-weight-bold text--primary">
              Status da compra (ERP)
            </span>
          </p>
          <p class="mb-1">
            <span class="font-weight text--secondary">
              Histórico de atualizações da compra no ERP
            </span>
          </p>
        </v-col>
        <v-col cols="12" sm="1" class="d-flex justify-end">
          <v-btn icon @click="onClickRefresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn icon @click="onClickExpand">
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="show">
        <px-lista-logs
          :logs="logs"
          :loading="loading"
          :on-change-options="onChangeOptions"
          :options-list="optionsList"
          @update="onClickRefresh"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import IntegracaoService from '@/api/integracao-service';
import paginationMixin from '@/mixins/pagination-mixin';

export default {
  mixins: [paginationMixin],
  props: {
    idCompra: String,
  },
  data() {
    return {
      show: false,
      logs: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 5,
        offset: 0,
        filter: '',
        sort: 'createdIn',
        sortDesc: true,
      },
      optionsList: {},
      loading: false,
    };
  },
  computed: {
    icon() {
      return this.show ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
  },
  methods: {
    onClickExpand() {
      this.show = !this.show;
    },
    onClickRefresh() {
      this.getLogs();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    onChangeFilter() {
      this.resetPage();
      this.getLogs();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getLogs();
    },
    getLogs() {
      this.loading = true;

      const params = {
        limit: this.listOptions.limit,
        offset: this.listOptions.offset,
        query: this.buildQuery(this.listOptions),
      };
      IntegracaoService.getLogsCompras(this.idCompra, params)
        .then((response) => {
          this.logs = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.getLogs();
  },
};
</script>
