<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Projeto</span>
        <div>
          {{ projeto | naoInformado }}
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Centro de custo</span>
        <div>
           {{ centroCusto | naoInformado }}
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Forma pagamento</span>
        <div>
          <px-forma-pagamento v-if="cotacao.formaPagamento"
                              :forma-pagamento="cotacao.formaPagamento"
                              :prazo-pagamento="cotacao.prazoPagamento"/>
          <span v-if="!cotacao.formaPagamento">Não informado</span>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Tipo de frete</span>
        <div>
          {{ cotacao.tipoFrete | naoInformado }}
        </div>
      </v-col>
    </v-row>
    <v-row dense class="mt-3">
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Entregar até</span>
        <div>
          <span v-if="cotacao.prazoEntrega">{{ cotacao.prazoEntrega | date }}</span>
          <span v-else>Não informado</span>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Valido até</span>
        <div>
          <span v-if="cotacao.validade">{{ cotacao.validade | date }}</span>
          <span v-else>Não informado</span>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Observação</span>
        <div>
          <div>
            <px-show-large-text :large-text="cotacao.observacao"/>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <span class="caption font-weight-bold">Finalidade da Compra</span>
        <div v-if="cotacao.finalidadeCompra">
          <px-finalidade-compra-value
            :finalidade-compra="cotacao.finalidadeCompra"/>
        </div>
        <div v-else>
          Não Informado
        </div>
      </v-col>
    </v-row>
    <v-row dense class="mt-3" v-if="cotacao.usuario">
      <v-col cols="12">
        <span class="caption font-weight-bold">Responsável</span>
        <div>
          {{ cotacao.usuario.nome }}
        </div>
      </v-col>
    </v-row>
    <v-row v-if="cotacao.arquivos && cotacao.arquivos.length">
      <v-col cols="12">
        <span class="caption font-weight-bold">Arquivos</span>
        <div>
          <px-anexo-chip :files="cotacao.arquivos"
                         download-base-path="files/download-comprador"/>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-subheader>Produtos</v-subheader>
          <v-divider/>
          <px-listagem-cotacao-produtos :cotacao="cotacao"/>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-subheader>Fornecedores</v-subheader>
          <v-divider/>
          <px-cotacao-detalhes-card-details-fornecedor :cotacao="cotacao"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListagemCotacaoProdutos from '../listagem/ListagemCotacaoProdutos.vue';
import CotacaoDetalhesCardDetailsFornecedor from './CotacaoDetalhesCardDetailsFornecedor.vue';

export default {
  props: {
    cotacao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    centroCusto() {
      return this.cotacao.centroCusto ? this.cotacao.centroCusto.descricao : null;
    },
    projeto() {
      return this.cotacao.projeto ? this.cotacao.projeto.descricao : null;
    },
  },
  components: {
    pxListagemCotacaoProdutos: ListagemCotacaoProdutos,
    pxCotacaoDetalhesCardDetailsFornecedor: CotacaoDetalhesCardDetailsFornecedor,
  },
};
</script>
