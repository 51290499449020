import axios from 'axios';


const getLogsIntegracao = (params) => axios.get('integracoes/logs', {
  params,
});

const getLogsCompras = (idCompra, params) => axios.get(`compras/${idCompra}/logs-integracoes`, {
  params,
});

export default {
  getLogsIntegracao,
  getLogsCompras,
};
