import Vue from 'vue';
import Vuetify, {
  VSnackbar, VBtn, VIcon, VRow, VCol, VSpacer, VBreadcrumbs, VTextField,
  VCard, VCardText, VCardActions, VCardTitle, VCardSubtitle,
  VSelect, VDatePicker, VMenu, VProgressCircular, VDialog,
  VList, VListItem, VListItemAction, VListItemContent, VListItemGroup,
  VListItemSubtitle, VListItemTitle, VToolbarTitle, VToolbar,
  VChip, VChipGroup, VTooltip, VAutocomplete, VContainer, VContent, VBadge,
  VDivider, VSubheader, VTextarea, VImg, VTimeline, VTimelineItem, VDataTable,
} from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import VuetifyToast from 'vuetify-toast-snackbar';
import PixLogo from '../components/PixLogo.vue';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VRow,
    VCol,
    VSpacer,
    VBreadcrumbs,
    VTextField,
    VCard,
    VCardText,
    VCardActions,
    VCardTitle,
    VCardSubtitle,
    VSelect,
    VDatePicker,
    VMenu,
    VProgressCircular,
    VDialog,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemGroup,
    VListItemSubtitle,
    VListItemTitle,
    VToolbarTitle,
    VToolbar,
    VChip,
    VChipGroup,
    VTooltip,
    VAutocomplete,
    VContainer,
    VContent,
    VBadge,
    VDivider,
    VSubheader,
    VTextarea,
    VImg,
    VTimeline,
    VTimelineItem,
    VDataTable,
  },
});

const vuetifyConfig = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken3,
        secondary: colors.orange.accent4,
        accent: colors.orange.accent4,
      },
    },
  },
  icons: {
    values: {
      customPix: {
        component: PixLogo,
      },
    },
  },
});

// fix para funcionar o toast nessa versão
// https://github.com/eolant/vuetify-toast-snackbar/issues/42
Vue.use(VuetifyToast, {
  $vuetify: vuetifyConfig.framework,
  x: null,
  y: 'bottom',
  timeout: 8000,
  color: null,
  showClose: true,
  closeTet: 'FECHAR',
});

export default vuetifyConfig;
