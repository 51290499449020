<template>
  <div class="text--primary">
    <v-row>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Aberto em</span>
        <div>
          {{ contratacao.createdIn | date(true) }}
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Forma de pagamento</span>
        <div>
          <px-forma-pagamento :forma-pagamento="contratacao.formaPagamento"
                              :prazo-pagamento="contratacao.prazoPagamento"/>
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <span class="caption font-weight-bold">Prazo de entrega</span>
        <div>
          {{ contratacao.prazoEntrega | pluralize(['dia corrido', 'dias corridos']) }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Observação</span>
        <div>
          <px-show-large-text :large-text="contratacao.observacao"/>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Arquivos</span>
        <div v-if="contratacao.arquivos.length">
          <px-anexo-chip :files="contratacao.arquivos"
                         :download-base-path="downloadPath"/>
        </div>
        <div v-else>
          Nenhum arquivo informado
        </div>
      </v-col>
    </v-row>
    <v-row v-if="contratacao.motivo">
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">
          Motivo de cancelamento
          <v-icon small color="error">mdi-cancel</v-icon>
        </span>
        <div>
          <px-show-large-text :large-text="contratacao.motivo"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    contratacao: Object,
  },
  computed: {
    downloadPath() {
      return 'cotacoes-fretes/file';
    },
  },
};
</script>
